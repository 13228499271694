/* μlogger
 *
 * Copyright(C) 2017 Bartek Fabiszewski (www.fabiszewski.net)
 *
 * This is free software; you can redistribute it and/or modify it under
 * the terms of the GNU General Public License as published by
 * the Free Software Foundation; either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful, but
 * WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program; if not, see <http://www.gnu.org/licenses/>.
 */

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #666;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #bce;
}

:link, :visited {
  color: #bce;
}

select {
  font-weight: normal;
  width: 150px;
  padding-top: 0.2em;
}

#container {
  display: flex;
  height: 100%;
}

#main {
  flex-grow: 1;
  order: 1;
  height: 100%;
}

#map-canvas {
  height: 100%;
}

#menu {
  font-family: "Open Sans", Verdana, sans-serif;
  font-size: 0.7em;
  font-weight: bold;
  float: right;
  overflow-x: hidden;
  overflow-y: auto;
  order: 2;
  width: 165px;
  height: 100%;
  color: white;
  background-color: #666;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#menu-content {
  padding: 10px 0 3em 10px;
}

#footer {
  line-height: 3em;
  position: fixed;
  bottom: 0;
  width: 165px;
  padding-left: 10px;
  color: lightgray;
  background-color: rgba(102, 102, 102, 90%);
}

#menu-button {
  font-size: 28px;
  font-weight: normal;
  line-height: 28px;
  position: absolute;
  z-index: 1900;
  top: 5px;
  right: 0;
  width: 30px;
  height: 35px;
  cursor: pointer;
  text-align: center;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #bce;
  border-radius: 11px 0 0 11px;
  background-color: #666;
}

#menu-button a {
  color: white;
}

#menu-button a::after {
  content: "»";
}

#menu.menu-hidden {
  width: 0;
}

#menu.menu-hidden #menu-button {
  font-weight: normal;
  border-color: white;
  background-color: rgba(0, 60, 136, 30%);
}

#menu.menu-hidden #menu-button a::after {
  content: "«";
}

#menu input,
#login input {
  width: 150px;
  text-align: center;
  border: 1px solid black;
}

#menu input[type="submit"],
#login input[type="submit"] {
  color: white;
  border: 1px solid white;
  background-color: black;
  cursor: pointer;
}

#menu input[type="checkbox"] {
  width: auto;
}

.menu-link {
  display: block;
  margin-top: 0.2em;
}

label[for="user"] {
  display: block;
  padding-top: 1em;
}

.section {
  display: block;
  padding-bottom: 10px;
}

.section:first-child {
  padding-top: 1em;
}

#input-file {
  display: none;
}

#summary div {
  padding-top: 0.3em;
}

#summary div img {
  margin-bottom: -2px;
}

#login {
  font-family: "Open Sans", Verdana, sans-serif;
  font-size: 0.8em;
  position: relative;
  top: 10%;
  width: 30%;
  min-width: 200px;
  margin: auto;
  padding: 30px;
  text-align: center;
  color: white;
  background-color: #444;
}

#title {
  font-size: 1.3em;
  padding-top: 0.6em;
  padding-bottom: 0.5em;
}

#subtitle {
  padding-bottom: 2em;
}

#error {
  padding-top: 1.2em;
  color: yellow;
}

#popup {
  font-family: "Open Sans", Verdana, sans-serif;
  max-width: 25em;
  background-color: #666;
}

#pheader {
  font-size: 0.9rem;
  float: left;
  padding-bottom: 0.5rem;
  color: #bce;
}

#pheader div {
  float: left;
  padding-right: 2em;
}

#pheader div img {
  background-image: radial-gradient(circle closest-side, #bfbfbc, #666);
}

#pbody {
  font-size: 0.8rem;
  line-height: 1.3rem;
  clear: both;
  padding-top: 0.2rem;
  white-space: nowrap;
  color: #e6e2e2;
  border-top: 1px solid #bce;
}

#pcomments {
  clear: both;
  padding: 1em;
  text-align: center;
  white-space: normal;
  color: #e6e6e6;
  border-radius: 10px;
  background-color: #777676;
}

#pimage {
  text-align: center;
}

#pimage img {
  max-width: 100%;
  max-height: 25em;
  cursor: pointer;
  border-radius: 10px;
}

#pimage img:hover {
  opacity: 0.7;
}

#pleft, #pright {
  display: inline-block;
  padding-top: 5px;
  padding-right: 20px;
}

#pleft img {
  background-image: radial-gradient(circle closest-side, #bfbfbc, #666);
}

#pbody .smaller {
  font-size: 0.9em;
  color: #cacaca;
}

#pfooter {
  font-size: 0.6rem;
  padding-top: 20px;
  color: #f0f8ff;
}

#pfooter div:first-child {
  width: 40%;
  float: left;
}

#pfooter div:last-child {
  width: 40%;
  float: right;
  text-align: right;
}

#bottom {
  position: relative;
  z-index: 10000;
  display: none;
}

#chart {
  font-family: "Open Sans", Verdana, sans-serif;
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
  height: 200px;
  padding: 0 10px;
  opacity: 0.8;
  background-color: white;
}

#chart-close {
  position: absolute;
  z-index: 10001;
  right: 4px;
  bottom: 166px;
  cursor: pointer;
}

.mi {
  font-style: italic;
  padding-right: 0.1em;
  color: white;
}

#modal {
  font-family: "Open Sans", Verdana, sans-serif;
  position: fixed;
  z-index: 10010;
  top: 0;
  left: 0;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: black; /* fallback */
  background-color: rgba(0, 0, 0, 40%);
}

#modal-header {
  position: absolute;
  top: -10px;
  right: 10px;
  margin: 0 auto;
  text-align: right;
}

#modal-header button {
  border: none;
  background-color: rgba(0, 0, 0, 0%);
}

#modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 0.9em;
  min-width: 300px;
  margin: 0 auto 15%;
  padding: 1em;
  color: white;
  border: 1px solid #888;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: rgba(102, 102, 102, 90%);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#modal-body .buttons {
  padding-top: 1em;
}

#modal input[type="text"],
#modal input[type="color"],
#modal input[type="number"],
#modal input[type="password"] {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: 0.8em 0;
  padding: 0.4em;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

#modal.image {
  overflow: hidden;
  padding-top: 0;
  background-color: rgba(45, 45, 45, 95%);
}

#modal.image #modal-body img {
  height: auto;
  max-width: 90vw;
  max-height: 87vh;
}

#modal.image #modal-body {
  text-align: center;
  background-color: rgb(45, 45, 45);
}

button {
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: white;
  border: 1px solid white;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #434343;
}

button > * {
  pointer-events: none;
}

#cancel {
  margin-top: 0.5em;
}

.red-button {
  float: right;
  padding: 0.1em 0.4em;
  color: white;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: red;
}

#user-menu {
  position: absolute;
  display: block;
  width: 130px;
  padding: 1em;
  border: 1px solid #888;
  background-color: gray;
}

#user-menu.menu-hidden, a.menu-hidden {
  display: none;
}

#user-menu a {
  display: block;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.icon {
  height: 1.4em;
  margin-right: 4px;
  vertical-align: text-top;
}

.menu-title {
  text-decoration: underline;
}

#config-form label {
  display: block;
}

#config-form label b {
  display: inline-block;
  text-align: right;
  width: 250px;
  margin-right: 10px;
  font-size: small;
  padding-top: 5px;
}

#config-form input[type="text"],
#config-form input[type="number"],
#config-form input[type="color"],
#config-form select {
  width: 150px;
  margin: 3px 0;
  padding: 2px 4px;
  box-sizing: border-box;
}

#config-form input[type="checkbox"] {
  margin: 0;
}

#config-form select {
  padding: 2px 0;
}

#config-form input[type="color"] {
  vertical-align: middle;
  padding: 0;
}

#config-form img {
  height: 13px;
  vertical-align: middle;
  margin: 0 5px;
}

.hidden {
  display: none;
}

/* alert */

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  width: 300px;
  background: #666;
  color: white;
  font-family: "Open Sans", Verdana, sans-serif;
  font-size: 0.8em;
  line-height: 20px;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 1em 0 1em -150px;
  padding: 6px 20px;
  border-radius: 5px;
  border-top: 1px solid #555;
  box-shadow: 10px 10px 10px -8px rgba(0, 0, 0, 30%);
  z-index: 100000;
  opacity: 0;
  transition: all 1s;
}

.alert.error {
  background: #d95b5b;
  border-top: 1px solid #d05858;
}

.alert.in {
  opacity: 1;
}

.alert.out {
  opacity: 0;
}

.alert button {
  position: absolute;
  top: -1px;
  right: 0;
  border: none;
  margin: 0;
  height: 100%;
  background: none;
  font-weight: normal;
  font-size: 15px;
}

.alert.spinner {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.alert.spinner > span {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: spinner-dot 1s infinite linear alternate;
  animation-delay: 0.5s;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  will-change: transform, opacity;
}

.alert.spinner > span::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: spinner-dot 1s infinite alternate;
  animation-delay: 1s;
}

.alert.spinner > span::before, .alert.spinner > span::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.alert.spinner > span::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: spinner-dot 1s infinite alternate;
  animation-delay: 0s;
}

@keyframes spinner-dot {
  0% {
    background-color: #9880ff;
  }

  50%, 100% {
    background-color: #ebe6ff;
  }
}

/* chart */
.ct-point {
  transition: 0.3s;
  stroke-width: 5px !important;
}

.ct-point:hover {
  cursor: pointer;
  stroke-width: 10px !important;
}

.ct-point-hilight {
  stroke-width: 10px !important;
}

.ct-point-selected {
  stroke-width: 10px !important;
  stroke: #f4c63d !important;
}

.ct-line {
  stroke-width: 2px !important;
}

.ct-axis-title {
  font-size: 0.8em;
}

/* openlayers popup */
.ol-popup {
  position: absolute;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #666;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 20%);
}

.ol-popup::after, .ol-popup::before {
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  content: " ";
  pointer-events: none;
  border: solid transparent;
}

.ol-popup::after {
  left: 48px;
  margin-left: -10px;
  border-width: 10px;
  border-top-color: #666;
}

.ol-popup::before {
  left: 48px;
  margin-left: -11px;
  border-width: 11px;
  border-top-color: #ccc;
}

.ol-popup-closer {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 30px;
  height: 30px;
  background-image: url("../../images/close.svg") !important;
  background-repeat: no-repeat !important;
}

.ol-overlay-container {
  background-color: #666;
}

/* Google Maps InfoWindow */
.gm-style .gm-style-iw-c {
  background-color: #666 !important;
  overflow: visible !important;
}

.gm-style .gm-style-iw-t::after {
  /* stylelint-disable-next-line max-line-length */
  background: linear-gradient(45deg, rgb(102, 102, 102) 50%, rgba(255, 255, 255, 0%) 51%, rgba(255, 255, 255, 0%) 100%) !important;
}

.gm-style-iw button {
  background-image: url("../../images/close.svg") !important;
  background-repeat: no-repeat !important;
}

.gm-style-iw button img {
  visibility: hidden;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: #666 !important;
}

#switcher {
  position: absolute;
  bottom: 12px;
  left: 10px;
  display: none;
  min-width: 200px;
}

.ol-layerswitcher {
  font-family: sans-serif;
  font-size: 0.9em;
  font-weight: bold;
  margin: 1px;
  padding: 0.5em;
  color: #fff;
  border: none;
  border-radius: 2px;
  background-color: rgba(0, 60, 136, 50%);
}

.ol-layerswitcher:hover {
  background-color: rgba(0, 60, 136, 70%);
}

.ol-layerswitcher label {
  display: block;
  clear: both;
  margin: 0.5em 0;
  cursor: pointer;
}

.ol-layerswitcher label:hover {
  color: #c8dcf2;
}

.ol-layerswitcher input {
  margin-right: 1em;
}

label.ol-datalayer {
  margin-top: 1.5em;
}

.ol-datalayer ~ .ol-datalayer {
  margin-top: 0.5em;
}

.ol-switcher-button {
  top: 6.6em;
  left: 0.5em;
}

.ol-touch .ol-switcher-button {
  top: 10em;
}
